#certificateModule{
    div {
        margin-bottom: 1em;
    }

    .image {
        position: relative;
        margin-bottom: 2em;
        image-orientation: from-image;
        max-width: 50em;
        height: 50em;

    }

    .uploaded-file-container{
        position: relative;
        width: 5em;
        margin-right: 2em;
    }

    .uploaded-file{
        font-size: 3em;
        padding: 0.4em;
        cursor: pointer;
        border: solid 0.01em gray;
        height: 1em;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
    }

    .delete-image{
        position: absolute;
        top: -0.7em;
        right: -0.7em;
        font-size: 1.1em;
        cursor: pointer;
        padding: 0.3em;
        background: white;
        border-radius: 1em;
    }

    .dropzonearea{
        width: 90%;
    }
}