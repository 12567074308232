#templateModule {

    form{
        margin-bottom: 3em;
    }

    .moduleText{
        margin-right: 1em;
    }

    h2 {
        color: black;
    }

    div {
        margin-bottom: 1em;
    }


    [contenteditable="true"]:focus{
        border:none;
        outline:none;
    }

    .notes{
        margin-top: 0;
    }

    .inline-input {
        min-width: 20em;
        margin-left: .5em;
    }

    .impact-label{
        width: 10em;
    }

    .impact{
        width: 30em;
        margin-left: .5em;
    }

    .full-width{
        margin-right: 1em;
    }

    

    img{
        width: 80%;
    }

    .btn-upload{
        width: 10em;
        margin-top: 1em;
    }

    .image {
        position: relative;
        margin-bottom: 2em;
        image-orientation: from-image;
        max-width: 50em;
        height: 50em;

    }

    .uploaded-file-container{
        position: relative;
        width: 5em;
        margin-right: 2em;
    }

    .uploaded-file{
        font-size: 3em;
        padding: 0.4em;
        cursor: pointer;
        border: solid 0.01em gray;
        height: 1em;
        width: 1em;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
    }

    .delete-image{
        position: absolute;
        top: -0.7em;
        right: -0.7em;
        font-size: 1.1em;
        cursor: pointer;
        padding: 0.3em;
        background: white;
        border-radius: 1em;
    }

    .dropzonearea{
        width: 90%;
    }

}
