#emergencyManagementPlans {

    color: @text;

    .institutionName{
        font-size: .5em;
    }

    .create-emergency-plan-button-holder{
        margin: 1em;
    }

    .emergencyManagementPlan-link{
        display: inline-flex;
        width: 100%;
    }

    .emergencyManagementPlan {
        padding: 1em 1em .4em;
        background-color: white;
        box-shadow: 0.1em 0.1em 0.01em #d9d7db;
        margin-top: 0.5em;
        color: black;
        justify-content: space-between;

    }

    .emergencyManagementPlan:hover {
        background-color: #d9d7db;
    }
        

    .emergencyManagementPlans{
        margin: 0 0 5em 0;
    }

    .planIcon{
        font-size: 1em;
        margin-right: .5em;
    }

    .emergencyManagementPlanName{
        display: inline-block;
        width: 80%;
        font-size: 1.5em;
    }

    .emergencyManagementPlanInstitutionName{
        margin: 0 2em;
    }

    .excel{
        cursor: pointer;
        justify-content: right;
    }
}