#emergencyManagementPlanDetail{
   .action{
       margin-top: 1em;
       margin-right: 2em;
   }

   .btn-archive{
       margin-left: 5em;

       @media only screen and (max-width : 1000px) {
        margin-left: 0;
      }
   }

   .btn-archive-save{
        margin-left: 7em;
        margin-top: 1em;
   }

   .btn-reopen{
        margin-left: 5em;
   }

   .pdf{
        margin-right: 1em;
        cursor: pointer;
   }
}