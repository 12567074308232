#templateModule {
    .templateModuleNarrative {
        .input {
            background: white;
            padding: .2em;
            width: 15em;
            margin-right: 1em;

            div {
                margin-bottom: 0;
            }
        }
    }
}