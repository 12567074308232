.menu-item{
    font-size: 1.2em;
    background-color: white;
    color: black;
    padding: 0.5em 1em;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    width: 12em;
}

.menu-item:hover{
    color: darkgray;
}
