#auditModule{

    .rating-row{
        width: 80%;
        justify-content: space-evenly;
    }

    .rating-title{
        font-size: 2em;
        font-weight: 300;
    }

    .score{
        font-size: 1.5em;
    }

    .question{
        margin-bottom: 1em;
        width: 80%;
    }

    @media (max-width : 1000px) {
        .question{
            width: 100%;
        }
    }

    .audit-label{
        font-weight: bold;
    }

    .flex-row{
        margin-top: .5em;
    }

    .audit-section{
        margin-right: 3em;
    }

    .audit-column{
        width: 15em;
    }

    .citation-text{
        max-width: 30em;
    }

    .answer-number{
        width: 2em;
    }

    .comments{
        width: 50em;
    }

    @media (max-width : 1000px) {
        .comments{
            width: 30em;
        }
    }

}