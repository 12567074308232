#safetyEventModule{
    .title{
      justify-content: space-between;
    }

    .print{
        cursor: pointer;
    }

    h2{
      margin-top: 2em
    }

    .history-paper {
      margin-top: 1em;
    }

    .history{
      padding: 1em;

      h3 {
        margin: 0;
      }

    }

    .width-22{
      margin-top: 0.7em;
    }

    .save{
      margin-top: 1.5em;
    }
}

@media print {
    body * {
      visibility: hidden;
    }

    .content * {
      visibility: visible;
    }



    .history{
        width: 100%;
    }

    .history, .history * {
      visibility: visible;

      .no-print, .no-print * {
        visibility: hidden;
      }
    }
    
  }