.menu{
    .opener {
        font-size: 1.5em;
        background-color: white;
        color: black;
        padding: 0.5em 1em;
        border-radius: 1.5em;
        margin-right: auto;
        margin-left: auto;
        cursor: pointer;
    }

    .opener:hover{
        color: darkgray;
    }

    .open{
        display: none;
    }

}