#templateModule {
    .templateModuleRow{
        .input {
            background: white;
            padding: .2em;

            div {
                margin-bottom: 0;
            }
        }

        .row-action{
            width: 6em;
            font-size: 1.1em;
            padding: 0.3em;
        }
        .add{
            margin-right: 1em;
        }

        .header{
            margin-bottom: 0;
            font-weight: bold;
        }

        .row {
            justify-content: space-between;
        }

        .adds{
            position: fixed;
            right: .5em;
            top: 8em;
            
            border: solid .01em;
            background: lightgray;
            padding: 1em;

            @media (max-width : 600px) {
                right: 0;
                padding: .5em;
            }
        }

        .committeeMember-header, .committeeMember-input {
        margin-right: 2em;
        width: 20%;
        min-width: 4em;

        @media (max-width : 600px) {
            width: 100%;
            margin-bottom: .5em;
        }
        }

        .evacuationLocation{
            width: 30em;
        }
    }
}