.fileviewer-dialog {
    .uploaded-file-container{
        margin: 1em;
    }
    
    .uploaded-file{
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 80%;
    }

    .uploaded-file-file{
        font-size: 5em;
        text-align: center;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;

    }

    .download-button{
      text-align: center;
      margin-top: 1em;
      margin-bottom: 1em;
    }
}
