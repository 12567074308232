#sidebar {
    background: #2f2e2e;
    height: 100%;
    min-height: 100em;
    top: 6em;
    position: absolute;
    border: 0px solid #000000;

    .module {
        white-space: pre-wrap;
        position: relative;
        border-radius: 0px 0px 0px 0px;
        -moz-border-radius: 0px 0px 0px 0px;
        -webkit-border-radius: 0px 0px 0px 0px;
        border: 0px solid #000000;
        color: @sideNavColor;
        height: 4em;
        text-align: left;
        padding: 1em;
        width: 15em;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#22262b+0,091324+100 */
        text-decoration: none;
        cursor: pointer;

        a:link {
            color: @sideNavColor;
        }

        a:hover {
            color: @sideNavHover;
        }
    }

    .module.active {
        background: @headlines !important;
        color: black !important;

        a:hover{
            color: black;
        }
    }

    .selectedBuildingName{
        font-weight: bold;
    }

    .linkStyle{
        width: 13em;

        @media (max-width : 1000px) {
            padding-right: 2em;
        }
    }

    .complete-check {
        position: absolute;
        color: green;
        left: 8em;
        bottom: 2.1em;
        font-size: 1.8em;
    }

    div {
        white-space: pre-wrap;

        a:link {
            color: white;
        }

        a:hover {
            color: @sideNavHover !important;
        }
    }

    .showNav-bar{
        position: absolute;
        top: 0.9em;
        left: 1.5em;
        font-size: 2em;
        color: white;

        @media (max-width : 1000px) {
            top: 0.6em;
        }

    }

    .mobile-nav{
        position: absolute;
        top: 4em;
        background-color: black;
        z-index: 99;
    }

    .desktop{
        display: block;
    }

    .tablet{
        display: none;

        .btn{
            height: 7em;
            font-size: 14px;
            display: block;
        }

        .complete-check{
            left: 8em;
            height: 1.2em;
        }
    }

    @media (max-width : 1000px) {
        .desktop{
            display: none;
        }

        .tablet{
            display: block;
        }
    }
}

@media (max-width : 1000px) {
    #sidebar {
        width: 0;
        top: 0;
    }
}
