.safetyEventEntry{
    .attendee-toggle{
        cursor: pointer;
    }

    .addAnotherOther{
        font-size: .8em;
        color: darkgray;
        margin-top: .5em;
        margin-left: .5em;
        cursor: pointer;
    }

    .addOtherName{
        width: 50%;
    }

    .subtype{
        width: 100%;
    }

    .flex-row{
        justify-content: space-between;
    }

    .MuiSelect-select{
        margin-top: 1em !important;
    }

    @media (max-width : 1000px) {
        .width-22{
            min-width: 10em;
        }
    }
}