.siteVisit{

    .MuiTab-root{
        @media (max-width : 600px) {
            min-width: 1em;
            max-width: 6.5em;
        }
    }

    .paper{
        padding: 1em;
    }

    .flex-row{
        width: 100%;
        margin-top: 1em;
    }

    .short{
        width: 30em;
    }

    .flex-col{
        @media (max-width : 600px) {
            flex-grow: 0;
        }
    }
    
    .flex-row.top-connected{
        margin-top: .5em;
    }

    .tab-action-row{
        justify-content: space-between;
    }

    .actions{
        margin-top: 0;
    }

    .section{
        margin-top: 5em;
    }

    .question{
        font-weight: bold;
    }

    .full-width{
        width: 100%;
    }

    .row{
        .flex-col{
            margin-right: 1em;
        }
    }

    .textbox-label{
        margin-bottom: .5em;
    }

    .top-actions{
        justify-content: space-between;
    }

    ul {
        list-style-type: circle;
        
      }

    ul, ol {
        padding: 0 1em;
        margin: 0;
    }
    
    li {
        padding-left: 1em;
    }

    .small-cols{
        .MuiTextField-root{
            width: 10em;
        }
    }

    .med-col{
        .MuiTextField-root{
            width: 20em;
        }
    }

    .large-col{
        .MuiTextField-root{
            width: 30em;
        }
    }

    .data-col{
        margin-top: 0.2em;
        width: 50em;
    }

    .row-title{
        margin-top: 1em;
        width: 10em;
    }

    .big-img{
        width: 70%;
    }

    .image {
        position: relative;
        margin-bottom: 2em;
        image-orientation: from-image;
        max-width: 50em;
        height: 50em;
    }

    .assets{
        margin-top:1em;
    }

    .uploaded-folder-container{
        position: relative;
        width: 5em;
        margin-right: 2em;
    }

    .uploaded-file-container{
        position: relative;
        width: 5em;
        margin-right: 2em;
    }

    .uploaded-file{
        font-size: 3em;
        padding: 0.4em;
        cursor: pointer;
        border: solid 0.01em gray;
        height: 1em;
        width: 1em;
    }

    .uploaded-file-caption{
        font-size: .7em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
    }

    .delete-image{
        position: absolute;
        top: -0.7em;
        right: -0.7em;
        font-size: 1.1em;
        cursor: pointer;
        padding: 0.3em;
        background: white;
        border-radius: 1em;
    }

    .dropzonearea {
        justify-content: center;
        .MuiDropzoneArea-root{
            @media (max-width : 800px) {
                border-radius: 50em;
                max-width: 17em;
            }
        }
    }
}

.share-dialog{
    padding: 0 2em 2em 2em;
    text-align: center;
  
    .shareEmail{
        min-width: 20em;
    }

    .share-dialog-email{
      margin-top: 1em;
    }
  
    .share-button-container{
      margin-top: 1em;
    }
}