#policy {
    ul{
        list-style-type: disc;
        padding-left: 2em;
    }

    li{
        margin-top: .5em;
    }

    .justification{
        margin-bottom: 1em;
    }

    .subPolicy{
        margin-bottom: 2em;
    }

    .acknowledged{
        margin-top: 1em;
        max-width: 20em;
        justify-content: space-between;
    }

    .acknowledge-check{
        margin-top: .5em;
    }

    .complete-check-container{
        position: relative;
        color:green;
    }

    .fa-check-circle{
        color:green;
    }

    .save-comments{
        margin: 0;
        width: 1em;
    }
}