.AddRemove.flex-row{
    margin-top: 0;
   
}

.AddRemove {
    .flex-col{
        margin-right: 0 !important;
    }
}

