#toast{
    position: fixed;
    top: 7em;
    width: 30em;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    opacity: .95;
    z-index: 99;

    .title {
        font-size: 1.2em;
        font-weight: bold;
        display: inline;
        margin-right:1em;
    }

    .toast{
        padding: 1em;
    }
    
    .success {
        background-color: @success;
    }

    .error {
        background-color: @error;
    }

    .message{
        display: inline;
    }

    .close-button{
        position: absolute;
        top: .1em;
        right: .2em;
        opacity: .6;
    }

    .fa{
        color: white;
        font-size: 1.2em;
    }

}