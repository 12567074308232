#policies {
    .policy-link{
        display: inline-flex;
        width: 100%;
    }
    
    .policy {
        border: solid #9a9a9a 1px;
        padding: 1em 1em .4em 1em;
        background-color: white;
        box-shadow: 0.1em 0.1em 0.01em #d9d7db;
        margin-top: 0.5em;
        color: black;
        justify-content: space-between;
    }

    .policy:hover {
        background-color: #d9d7db;
    }
        
    .policyTitle{
        display: inline-block;
        width: 70%;
        font-size: 1.5em;
    }

    .complete-check-container{
        position: relative;
        margin-right: 1em;
        color:  green;
    }

    .fa-check-circle{
        color:green;
    }

    .check-placeholder{
        display: inline-block;
        width: 1em;
    }
}